@media screen and (min-width: 1px) {
    .container {
        background-size: 800px;
        background-position-x: center;
        background-attachment: fixed;
        background-color: #000;
        background-repeat: no-repeat;
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        background-color: rgba(0, 0, 0, .5);
        padding: 30px;
        border-radius: 4px;
    }

    .container h1,
    .container h2 {
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 300;
    }

    .container h1 {
        font-size: 1.5em;
    }

    .photographer {
        position: absolute;
        top: 70px;
        right: 25px;
        z-index: 1;
        padding: 5px 10px;
        font-size: 11px;
        background-color: rgba(0, 0, 0, .7);
    }
}

@media screen and (min-width: 800px) {
    .container {
        background-size: cover;
        height: 100vh;
        align-items: center;
        margin-top: -60px;
    }

    .container h1,
    .container h2 {
        font-weight: 400;
    }

    .container h1 {
        font-size: 2.4em;
    }

    .photographer {
        top: calc(100vh - 50px);
        right: 15px;
        font-size: 14px;
    }
}
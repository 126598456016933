@media screen and (min-width: 1px) {
    .content {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .row {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 80px;
    }

    .item {
        width: 100%;
        margin: 15px;
        padding: 20px;
    }

    .iframe {
        width: 100%;
        height: 100%;
        border: 0;
    }
}

@media screen and (min-width: 800px) {
    .row {
        flex-direction: row;
    }

    .item {
        width: 50%;
        border-bottom: none;
    }
}
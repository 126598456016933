.container {
    width: 95%;
    margin: 0 auto;
    padding: 5px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
}

.container span {
    display: block;
}

.date {
    font-weight: 700;
}
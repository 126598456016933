@import '~antd/dist/antd.css';
@import url('https://fonts.googleapis.com/css?family=Dosis:300,400,700&display=swap');

@media screen and (min-width: 1px) {
    body {
        background-color: #000;
        color: #e7e7e7;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    div,
    span,
    a {
        color: #e7e7e7;
        font-family: 'Dosis', sans-serif;
    }
}

@media screen and (min-width: 800px) {
    .button {
        font-size: 1.5em;
        padding: 20px 35px;
    }
}

.ant-table {
    z-index: -9999;
}

.ant-table-thead * {
    color: #e7e7e7 !important;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: none !important;
}

.ant-table th {
    background-color: #000 !important;
}

.ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-item a {
    color: #e7e7e7 !important;
    background-color: #000 !important;
}

@media screen and (min-width: 1px) {
    .container {
        height: 300px;
        background-color: #000;
        background-attachment: fixed;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .content {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 1.6em;
    }

    .content>* {
        margin: 0;
        padding: 0;
    }

    .photographer {
        position: absolute;
        top: 70px;
        right: 25px;
        z-index: 1;
        padding: 5px 10px;
        font-size: 11px;
        background-color: rgba(0, 0, 0, .7);
    }
}

@media screen and (min-width: 800px) {
    .container {
        height: 900px;
        background-size: cover;
    }

    .photographer {
        top: 920px;
        right: 35px;
        font-size: 14px;
    }
}
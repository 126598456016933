@media screen and (min-width: 1px) {
    .container {
        background-image: url('../../assets/images/gf_5.jpg');
        height: calc(100vh - 60px);
        background-size: cover;
        background-position-x: center;
        background-attachment: fixed;
        background-color: #000;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .content {
        background-color: rgba(0, 0, 0, .5);
        padding: 30px;
        border-radius: 5px;
        font-size: 1.8em;
        text-transform: uppercase;
    }

    .photographer {
        position: absolute;
        top: 70px;
        right: 25px;
        padding: 10px;
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        font-size: .8em;
    }
}

@media screen and (min-width: 800px) {
    .photographer {
        top: calc(100vh - 50px);
        right: 15px;
        font-size: .95em;
    }
}
@media screen and (min-width: 1px) {
    .headerContainer {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .5);
        padding: 30px;
        border-radius: 4px;
    }

    .headerContainer h1,
    .headerContainer h2 {
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 300;
    }

    .headerContainer h1 {
        font-size: 3em;
    }

    .bottomContent {
        margin: 0 auto;
        padding: .5rem 1rem;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
    }

    .bio {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: flex-start;
        max-width: 1200px;
        margin: 0 auto;
        padding: .5rem 1rem;
    }

    .imageContainer {
        width: 100%;
        padding: 15px;
        text-align: center;
    }

    .imageContainer img {
        width: 60%;
        margin: 0 auto;
    }

    .textContainer {
        width: 100%;
        padding: .5rem;
    }

    .bio p {
        font-size: 1.2em;
        line-height: 1.5;
        max-width: 1000px;
    }

    .bottomContent iframe {
        width: 100%;
        height: 300px;
        margin-top: 15px;
    }

    .listen,
    .shows {
        width: 95%;
        margin: 0 auto;
    }

    .listen {
        margin-bottom: 30px;
    }

    .show {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .show span,
    .listen span {
        display: block;
        font-size: 1.2em;
        line-height: 1.5;
    }

    .showDate {
        font-weight: 900;
    }

    .subtitle {
        font-size: 1.8rem;
    }
}

@media screen and (min-width: 800px) {

    .bottomContent {
        flex-direction: row;
        justify-content: space-between;
    }

    .listen {
        width: 65%;
        padding: 15px;
        margin-bottom: 0;
    }

    .shows {
        width: 35%;
        padding: 15px;
    }

    .headerContainer h1,
    .headerContainer h2 {
        font-weight: 400;
    }

    .headerContainer h1 {
        font-size: 4.6em;
    }

    .bio {
        flex-direction: row;
        padding: 1rem;

    }

    .imageContainer {
        width: 30%;
        margin: 0 auto;
    }

    .imageContainer img {
        width: 100%;
    }

    .subtitle {
        font-size: 2.5rem;
    }
}

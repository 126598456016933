@media screen and (min-width: 1px) {
    .container {
        border: 2px solid #e7e7e7;
        border-radius: 5px;
        padding: 10px 25px;
        font-size: 1em;
        cursor: pointer;
        background: transparent;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        background-color: rgba(0, 0, 0, .6);
        transition: all 200ms;
        margin: 15px;
        /* z-index: 999; */
    }

    .container:hover {
        background-color: #e7e7e7;
        color: #000;
    }

    .container:hover i {
        color: #000;
    }

    .icon {
        margin-left: 15px;
        transition: all 200ms;
    }

}

@media screen and (min-width: 800px) {
    .container {
        font-size: 1.2em;
        padding: 15px 25px;
    }
}
.sidebarContainer {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 70%;
    overflow: auto;
    background: #000;
    color: #e7e7e7;
    transition: 300ms;
    z-index: 9999999;
}

.link {
    font-size: 2.5em;
    margin: 3px 3px 3px 15px;
}

.topBar {
    width: 100%;
    background-color: #000000;
    height: 60px ;
    z-index: 200;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.siteTitle {   
    position: absolute;
    top: 9px;
    left: 15px;
    font-size: 2em;
    text-transform: uppercase;
}

.links {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    font-size: 2.2em;
    margin: 8px;
    padding: 15px;
}

.menuIcon {
    position: fixed;
    right: 15px;
    top: 18px;
}
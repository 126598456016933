@import url(https://fonts.googleapis.com/css?family=Dosis:300,400,700&display=swap);
@media screen and (min-width: 1px) {
    body {
        background-color: #000;
        color: #e7e7e7;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    div,
    span,
    a {
        color: #e7e7e7;
        font-family: 'Dosis', sans-serif;
    }
}

@media screen and (min-width: 800px) {
    .button {
        font-size: 1.5em;
        padding: 20px 35px;
    }
}

.ant-table {
    z-index: -9999;
}

.ant-table-thead * {
    color: #e7e7e7 !important;
}

.ant-table-tbody>tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)>td {
    background: none !important;
}

.ant-table th {
    background-color: #000 !important;
}

.ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-item a {
    color: #e7e7e7 !important;
    background-color: #000 !important;
}

.App_container__1MQN3 {
  padding-top: 60px;
  /* max-width: 2400px; */
  margin: 0 auto;
}
@media screen and (min-width: 1px) {
    .GFButton_container__1H-fF {
        border: 2px solid #e7e7e7;
        border-radius: 5px;
        padding: 10px 25px;
        font-size: 1em;
        cursor: pointer;
        background: transparent;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
        background-color: rgba(0, 0, 0, .6);
        transition: all 200ms;
        margin: 15px;
        /* z-index: 999; */
    }

    .GFButton_container__1H-fF:hover {
        background-color: #e7e7e7;
        color: #000;
    }

    .GFButton_container__1H-fF:hover i {
        color: #000;
    }

    .GFButton_icon__3dqPb {
        margin-left: 15px;
        transition: all 200ms;
    }

}

@media screen and (min-width: 800px) {
    .GFButton_container__1H-fF {
        font-size: 1.2em;
        padding: 15px 25px;
    }
}
@media screen and (min-width: 1px) {
    .PageHeaderFull_container__2Ry-w {
        background-size: 800px;
        background-position-x: center;
        background-attachment: fixed;
        background-color: #000;
        background-repeat: no-repeat;
        width: 100%;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        text-align: center;
        background-color: rgba(0, 0, 0, .5);
        padding: 30px;
        border-radius: 4px;
    }

    .PageHeaderFull_container__2Ry-w h1,
    .PageHeaderFull_container__2Ry-w h2 {
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 300;
    }

    .PageHeaderFull_container__2Ry-w h1 {
        font-size: 1.5em;
    }

    .PageHeaderFull_photographer__2Je1h {
        position: absolute;
        top: 70px;
        right: 25px;
        z-index: 1;
        padding: 5px 10px;
        font-size: 11px;
        background-color: rgba(0, 0, 0, .7);
    }
}

@media screen and (min-width: 800px) {
    .PageHeaderFull_container__2Ry-w {
        background-size: cover;
        height: 100vh;
        align-items: center;
        margin-top: -60px;
    }

    .PageHeaderFull_container__2Ry-w h1,
    .PageHeaderFull_container__2Ry-w h2 {
        font-weight: 400;
    }

    .PageHeaderFull_container__2Ry-w h1 {
        font-size: 2.4em;
    }

    .PageHeaderFull_photographer__2Je1h {
        top: calc(100vh - 50px);
        right: 15px;
        font-size: 14px;
    }
}
@media screen and (min-width: 1px) {
    .Home_headerContainer__1ywea {
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: rgba(0, 0, 0, .5);
        padding: 30px;
        border-radius: 4px;
    }

    .Home_headerContainer__1ywea h1,
    .Home_headerContainer__1ywea h2 {
        margin: 0;
        text-transform: uppercase;
        margin-bottom: 5px;
        font-weight: 300;
    }

    .Home_headerContainer__1ywea h1 {
        font-size: 3em;
    }

    .Home_bottomContent__-HjAo {
        margin: 0 auto;
        padding: .5rem 1rem;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
    }

    .Home_bio__1jn2B {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-around;
        align-items: flex-start;
        max-width: 1200px;
        margin: 0 auto;
        padding: .5rem 1rem;
    }

    .Home_imageContainer__2xfV0 {
        width: 100%;
        padding: 15px;
        text-align: center;
    }

    .Home_imageContainer__2xfV0 img {
        width: 60%;
        margin: 0 auto;
    }

    .Home_textContainer__wnA7G {
        width: 100%;
        padding: .5rem;
    }

    .Home_bio__1jn2B p {
        font-size: 1.2em;
        line-height: 1.5;
        max-width: 1000px;
    }

    .Home_bottomContent__-HjAo iframe {
        width: 100%;
        height: 300px;
        margin-top: 15px;
    }

    .Home_listen__JB4A8,
    .Home_shows__37W9T {
        width: 95%;
        margin: 0 auto;
    }

    .Home_listen__JB4A8 {
        margin-bottom: 30px;
    }

    .Home_show__1dTwW {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .Home_show__1dTwW span,
    .Home_listen__JB4A8 span {
        display: block;
        font-size: 1.2em;
        line-height: 1.5;
    }

    .Home_showDate__1tuVo {
        font-weight: 900;
    }

    .Home_subtitle__2-2TD {
        font-size: 1.8rem;
    }
}

@media screen and (min-width: 800px) {

    .Home_bottomContent__-HjAo {
        flex-direction: row;
        justify-content: space-between;
    }

    .Home_listen__JB4A8 {
        width: 65%;
        padding: 15px;
        margin-bottom: 0;
    }

    .Home_shows__37W9T {
        width: 35%;
        padding: 15px;
    }

    .Home_headerContainer__1ywea h1,
    .Home_headerContainer__1ywea h2 {
        font-weight: 400;
    }

    .Home_headerContainer__1ywea h1 {
        font-size: 4.6em;
    }

    .Home_bio__1jn2B {
        flex-direction: row;
        padding: 1rem;

    }

    .Home_imageContainer__2xfV0 {
        width: 30%;
        margin: 0 auto;
    }

    .Home_imageContainer__2xfV0 img {
        width: 100%;
    }

    .Home_subtitle__2-2TD {
        font-size: 2.5rem;
    }
}


@media screen and (min-width: 1px) {
    .Shows_content__1u4-2 {
        width: 95%;
        margin: 0 auto;
        max-width: 1500px;
    }
}

.ShowsMobile_container__25Y9c {
    width: 95%;
    margin: 0 auto;
    padding: 5px 0;
    margin-bottom: 10px;
    border-bottom: 1px solid #e7e7e7;
}

.ShowsMobile_container__25Y9c span {
    display: block;
}

.ShowsMobile_date__24sJX {
    font-weight: 700;
}
@media screen and (min-width: 1px) {
    .PageHeader_container__3U5dA {
        height: 300px;
        background-color: #000;
        background-attachment: fixed;
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .PageHeader_content__q2PLh {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 30px;
        background-color: rgba(0, 0, 0, .5);
        border-radius: 5px;
        text-transform: uppercase;
        font-size: 1.6em;
    }

    .PageHeader_content__q2PLh>* {
        margin: 0;
        padding: 0;
    }

    .PageHeader_photographer__3F9c- {
        position: absolute;
        top: 70px;
        right: 25px;
        z-index: 1;
        padding: 5px 10px;
        font-size: 11px;
        background-color: rgba(0, 0, 0, .7);
    }
}

@media screen and (min-width: 800px) {
    .PageHeader_container__3U5dA {
        height: 900px;
        background-size: cover;
    }

    .PageHeader_photographer__3F9c- {
        top: 920px;
        right: 35px;
        font-size: 14px;
    }
}
.TopNavMobile_sidebarContainer__1UV1v {
    display: flex;
    flex-direction: column;
    position: fixed;
    height: 100vh;
    width: 70%;
    overflow: auto;
    background: #000;
    color: #e7e7e7;
    transition: 300ms;
    z-index: 9999999;
}

.TopNavMobile_link__2n0B0 {
    font-size: 2.5em;
    margin: 3px 3px 3px 15px;
}

.TopNavMobile_topBar__BWpA1 {
    width: 100%;
    background-color: #000000;
    height: 60px ;
    z-index: 200;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.TopNavMobile_siteTitle__3qqDZ {   
    position: absolute;
    top: 9px;
    left: 15px;
    font-size: 2em;
    text-transform: uppercase;
}

.TopNavMobile_links__3osxe {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    font-size: 2.2em;
    margin: 8px;
    padding: 15px;
}

.TopNavMobile_menuIcon__3L1XM {
    position: fixed;
    right: 15px;
    top: 18px;
}
.TopNavDesktop_container__8lNXP {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000;
    height: 60px;
    padding: 20px 0;
    z-index: 200;
}

.TopNavDesktop_siteTitle__2SApa {
    font-size: 3.5em;
    text-transform: uppercase;
    margin: 30px;
}

.TopNavDesktop_links__2naXG {
    font-size: 1.5em;
}

.TopNavDesktop_links__2naXG>* {
    margin: 0 20px;
}

@media screen and (min-width: 1px) {
    .Contact_container__1tx2y {
        background-image: url(/static/media/gf_5.418777a0.jpg);
        height: calc(100vh - 60px);
        background-size: cover;
        background-position-x: center;
        background-attachment: fixed;
        background-color: #000;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .Contact_content__3_1v- {
        background-color: rgba(0, 0, 0, .5);
        padding: 30px;
        border-radius: 5px;
        font-size: 1.8em;
        text-transform: uppercase;
    }

    .Contact_photographer__1dm2U {
        position: absolute;
        top: 70px;
        right: 25px;
        padding: 10px;
        background-color: rgba(0, 0, 0, .7);
        color: #fff;
        font-size: .8em;
    }
}

@media screen and (min-width: 800px) {
    .Contact_photographer__1dm2U {
        top: calc(100vh - 50px);
        right: 15px;
        font-size: .95em;
    }
}
@media screen and (min-width: 1px) {
    .Music_content__2LGzX {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 20px auto 0 auto;
    }

    .Music_content__2LGzX h1 {
        margin: 0;
    }

    .Music_playlist__38AxS {
        padding: 15px;
        margin-bottom: 50px;
    }

    .Music_playlist__38AxS iframe {
        width: 100%;
    }

    .Music_playlistHeader__1qfvg {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .Music_playlist__38AxS img {
        width: 150px;
        height: 150px;
        margin-right: 15px;
    }
}

@media screen and (min-width: 1px) {
    .Videos_content__3s8Tk {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Videos_row__3Pfcw {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 80px;
    }

    .Videos_item__jUNle {
        width: 100%;
        margin: 15px;
        padding: 20px;
    }

    .Videos_iframe__27wqF {
        width: 100%;
        height: 100%;
        border: 0;
    }
}

@media screen and (min-width: 800px) {
    .Videos_row__3Pfcw {
        flex-direction: row;
    }

    .Videos_item__jUNle {
        width: 50%;
        border-bottom: none;
    }
}

@media screen and (min-width: 1px) {
    .content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        max-width: 1200px;
        margin: 20px auto 0 auto;
    }

    .content h1 {
        margin: 0;
    }

    .playlist {
        padding: 15px;
        margin-bottom: 50px;
    }

    .playlist iframe {
        width: 100%;
    }

    .playlistHeader {
        display: flex;
        flex-direction: row;
        margin-bottom: 10px;
    }

    .playlist img {
        width: 150px;
        height: 150px;
        margin-right: 15px;
    }
}
